
<script>

import { log, webRequestManager } from 'organizer-common';
import { SerializableObject } from 'organizer-common';

import { synchronizationService } from '@/service/SynchronizationService.js';
import { default as ResourceComponent } from '@/ui/block/ResourceComponent.vue';
import { default as TitleResourceCreateComponent } from '@/ui/block/TitleResourceCreateComponent.vue';

export default {
  name: 'TaskComponent',
  components: {
    ResourceComponent,
    TitleResourceCreateComponent
  },
  data: function() {
    return {
      // Initialize variables:
      rootResource: null
    }
  },
  created: async function() {
    // Try block:
    try {
      // Log message:
      console.log("TaskComponent.created(): Entered method");
      // Load root resource:
      await this.loadRootResource();
    } finally {
      // Log message:
      console.log("TaskComponent.created(): Exiting method");
    }
  },
  computed: {
  },
  watch: {
    'rootResource': function() {
      // Log message:
      log.message("TaskComponent.watch:rootResource(): Entered method");
    }
  },
  methods: {
    loadRootResource: async function() {
      // Try block:
      try {
        // Log message:
        log.message("TaskComponent.loadRootResource(): Entered method");
        // Execute call:
        var response = await webRequestManager.request("post", "storage", "/account.root.resource.and.child.list.v1", { "category": "task" });
        this.rootResource = new SerializableObject(response['resource']);
        // Set parent for resources in child resource list:
        for(let child of this.rootResource.childList) {
          // Log message:
          log.message("TaskComponent.loadRootResource(): Iterating over child in list", { "child text": child.text });
          // Set parent:
          child.parent = this.rootResource;
          // Set parent for resources in child resource list:
          for(let grandchild of child.childList) {
            // Log message:
            log.message("TaskComponent.loadRootResource(): Iterating over each child of child in list", { "grandchild text": grandchild.text });
            // Set parent:
            grandchild.parent = child;
          }
        }
        // Start synchronization monitor:
        synchronizationService.startMonitorForCategory("task");
        // Register resouce in synchronization service:
        synchronizationService.registerResourceAndDescendents(this.rootResource);
      } finally {
        // Log message:
        log.message("TaskComponent.loadRootResource(): Exiting method");
      }
    },
    onRefreshEvent: function() {
      // Log message:
      log.message("TaskComponent.onRefreshEvent(): Entered handle refresh event");
      // Load root container:
      this.loadRootContainer();
      // Load current container:
      this.loadCurrentContainer();
      // Force update:
      this.$forceUpdate();
      // Log message:
      log.message("TaskComponent.onRefreshEvent(): Exiting handle refresh event");
    }
  },
  filters: {
  }
}

</script>

<style lang="scss">

</style>

<template>
  <div class="sample">
    <div v-if="!rootResource">
      <div>No root resource</div>
    </div>
    <div v-if="rootResource">
      <div class="mb-3">
        <TitleResourceCreateComponent :parameterParent="rootResource" :depth="0" :category="'task'" :placeholder="'What do you need to do?'" />
      </div>
      <div v-if="rootResource.childList">
        <div v-for="child in rootResource.childList" :key="child.serializableSource.index">
          <div v-if="child.archiveDate == null">
            <ResourceComponent :parameterResource="child" :parameterParent="rootResource" :depth="0" :flagHighlight="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
